export default {
  proxy_path: "https://6p3ng7kc0l.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://2084xk267i.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "191",
  crm_base_url: "staging.riotinto.forwoodsafety.com",
  forwood_id_url: "https://id.staging.riotinto.forwoodsafety.com?redirect_uri=https://mapreport.staging.riotinto.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.riotinto.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-1_xP2FSZ7LB",
    userPoolWebClientId: "91u8nhe3dfrih96egj13p73q3",
    cookieStorage: {
      domain: ".staging.riotinto.forwoodsafety.com",
      secure: true
    }
  }
};
